import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/greg/Desktop/projects/portfolio/src/components/page.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`I started at `}<a parentName="p" {...{
        "href": "https://vic.ai"
      }}>{`vic.ai`}</a>{` in 2020 right before covid fully took over. Initially
I was looking to get back in the office and they had a "headquarters" in new
york (where I interviewed), so it made a great fit. That quickly changed as the
pandemic got worse and soon there were only a few of us left in the office
while most chose to work remotely.`}</p>
    <p>{`Unlike my previous company however, `}<a parentName="p" {...{
        "href": "https://vic.ai"
      }}>{`vic.ai`}</a>{` has basically always been
remote-first as our founders were from Norway and much of the engineering team
initially started in Europe. This made adapting to covid-life a fairly easy
transition for the company as a whole and eventually I got used to working
remotely yet again...`}</p>
    <h2>{`the early days`}</h2>
    <p>{`When I first joined, the company had just raised a `}<a parentName="p" {...{
        "href": "https://www.crunchbase.com/funding_round/vic-ai-series-a--69dd8057"
      }}>{`Series A`}</a>{` which was
heavily tied to one large contract. Much of the engineering team was focused on
onboarding that customer, however our product lead, a designer and I peeled off
to start revamping and redesigning much of the initial feature set.`}</p>
    <p>{`After a lot of hard work (and a decent amount of shimming that's probably all
too common at startups), the rest of the team finally got that customer on the
platform. They joined us in readying a more generalized and stable version of
the tool that was easier to sell to a broad array of customers. Our user base,
accountants, were particularly used to interactive spreadsheets and we dove
pretty deep into building something that supported efficient keyboard shortcuts
while also having a more modern feel.`}</p>
    <p>{`While I `}<em parentName="p">{`certainly`}</em>{` won't say we made all the right decisions at this early
stage, we seemed to have done something right because in September 2021 we
succeeded in securing a `}<a parentName="p" {...{
        "href": "https://techcrunch.com/2021/09/01/autonomous-accounting-platform-vic-ai-raises-50m-round-led-by-iconiq-growth/"
      }}>{`Series B`}</a>{`.`}</p>
    <h2>{`our ui stack`}</h2>
    <p>{`When I started as a senior engineer, we had been using `}<inlineCode parentName="p">{`angular`}</inlineCode>{` despite the
`}<a parentName="p" {...{
        "href": "https://blog.angular.io/discontinued-long-term-support-for-angularjs-cc066b82e65a"
      }}>{`discontinued long term support`}</a>{`. I surfaced to our product lead and CEO that
we'd eventually have to transition away from it but understood that a migration
was hard pill to swallow for such an early stage company. I knew we could
cross-load frameworks but coming up with that solution would take some time to
get right.`}</p>
    <p>{`So, while we pushed towards the Series B round, I focused on cleaning up the
existing codebase, introducing more reusable components and reducing our
dependence on `}<inlineCode parentName="p">{`angular`}</inlineCode>{` via more vanilla js. We eventually phased out all the
angular "services" in favor of top-level component state and vanilla utility
functions.`}</p>
    <p>{`Once the Series B was out of the way, we started hiring more aggressively and
I set up a bridge component so we could drop into `}<inlineCode parentName="p">{`react`}</inlineCode>{` and `}<inlineCode parentName="p">{`redux`}</inlineCode>{` at any
level of the application. Given that this was a large codebase with ~1,000
active users, we didn't have the option to pause releases for months while we
ported everything over in one mega-migration.`}</p>
    <p>{`This more granular, inside-out approach has worked quite well and we're now
just a few components away from removing `}<inlineCode parentName="p">{`angular`}</inlineCode>{` entirely. Once we've
replaced it and its router at the top level, we'll drop `}<inlineCode parentName="p">{`angular-cli`}</inlineCode>{` in favor
of a more modern bundler like `}<inlineCode parentName="p">{`parcel`}</inlineCode>{` or `}<inlineCode parentName="p">{`vite`}</inlineCode>{`.`}</p>
    <h2>{`what i've learned`}</h2>
    <p>{`Even though I'm happy with the overall approach, there are still things that in
hindsight I probably would've done differently. Depending on the framework
you're migrating from -- as well as the one you're migrating to -- a
cross-loading setup is actually not too hard to put in place. This is actually
a huge advantage of `}<inlineCode parentName="p">{`react`}</inlineCode>{` and its runtime vs frameworks like `}<inlineCode parentName="p">{`svelte`}</inlineCode>{` and
`}<inlineCode parentName="p">{`angular`}</inlineCode>{` that are coupled more closely with a specific compiler.`}</p>
    <p>{`Had I known it would only take me a week or so to explore and set up, I
would have pushed the stakeholders harder to start that move earlier. It's
funny also because I had one or two engineers write off the possibility of
cross-loading entirely, figuring that no two ui frameworks would play nicely
together. Now that I've seen it in action, I can see the potential of low
touch exploration that teams can do to quickly sample a framework in an
isolated portion of an application or use one to tackle a specific performance
issue (though obviously that comes with the maintenance tradeoff of maintaining
multiple frameworks, so there'd have to be very good rationale for it).`}</p>
    <p>{`In any case, we're about done with that migration, the team has grown and I've
since been promoted to Lead UI Engineer and subsequently Director of UI
Engineering. Now that were almost through with the migration and have more
people on them team, my job is transitioning to...`}</p>
    <ul>
      <li parentName="ul">{`Early stage technical specifications and planning`}</li>
      <li parentName="ul">{`Supporting members of the team as they drive more projects themselves`}</li>
      <li parentName="ul">{`Cross-stack architecture and tech debt`}</li>
    </ul>
    <p>{`While in the world of engineering meetings are often referred to as terrible
thing, I've come to appreciate the ones with a clear purpose and outcome; for
example spreading context, driving consensus around a key decision, or even one
on ones to help an individual get unblocked or plan their week. As engineers
and for individual contributors in general, it's very easy to forget how
important `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Conway%27s_law"
      }}>{`communication`}</a>{` and cross-team / cross-stack vision really is. If
you make quick decisions based on personal preference rather than clear
ratitonale that's been discussed throughout the team, much of your work is
bound to be reverted by the next person who just sees it all as spaghetti code
compared to their personal preferences.`}</p>
    <h2>{`what's to come`}</h2>
    <p>{`I'm still figuring this question out to some extent. While I have to start
letting go of more low-level decisions at work and instead trusting newer
members of the team to make them, I still love building and try my best to keep
up with the industry. While my expertise is definitely in UI engineering, I'm
now more interested in full-stack architecture at least from a high level. That
being said I also care pretty deeply about product and design (which was my
initial segue into the engineering world). Even workflow and project management
piques my interest because without organization and focus, it's really hard to
bring any meaningful endeavor to life.`}</p>
    <p>{`It's always been a goal of mine to solve problems on a broader scale. I think
softwares' real promise is its reach, and therefore delivering solutions to a
wide userbase has always excited me. Maybe the wide breadth of interests will
make me a good fit for higher level positions and decision making. I'd love to
start a software company at some point but that will take more practice when it
comes to trust and comfort with delegating decisions.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      